import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material//Chip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as xlsx from "xlsx";
import EditIcon from "@mui/icons-material/Edit";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import "./style.css";
import EmptyRecords from "components/EmptyRecords";

function SkillBoard() {
  const [userdata, setUserData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectSkill, setSelectSkill] = useState("2");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [age, setAge] = useState("");

  useEffect(() => {
    Promise.all([getSkillData(), getAgeGroups()]).then((values) => {
      console.log(values);
    });
  }, []);

  const getSkillData = async () => {
    try {
      const query = {
        age,
      };
      const contents = await getAllSkills(query);
      if (contents.data.status === 200) {
        setSkills(contents.data.data);
      }
    } catch (err) {}
  };

  const getAgeGroups = async () => {
    try {
      const query = {
        id: "1",
      };
      // const contents = await getAllSkills(query);
      // if (contents.data.status === 200) {
      //   setSkills(contents.data.data);
      //   // console.log(contents);
      // }
    } catch (err) {}
  };

  const getFilter = async () => {
    try {
      const query = {
        id: selectSkill,
      };
      const contents = await getAllSkills(query);
      if (contents.data.status === 200) {
        setSkills(contents.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const reset = async () => {
    setAge("");
    try {
      const query = {
        age: "",
      };
      const contents = await getAllSkills(query);
      if (contents.data.status === 200) {
        setSkills(contents.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const reportDownload = () => {
    const dataToExport = [];
    skills.forEach(({ top_score, child }) => {
      dataToExport.push({
        Name: child.first_name + " " + child.last_name,
        "Top Score": top_score,
        "Parent User": child.parent.first_name + " " + child.parent.last_name,
        Date: moment(child?.updated_at).utc().format("YYYY-MM-DD"),
      });
    });
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Users");
    xlsx.writeFile(workbook, "users.xlsx");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Skill Board
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Introduce new skills to our system, enhancing the student&rsquo;s abilities as they
              progress. Once added, skills become a permanent part of their profile, allowing for
              continuous improvement. While modifications are allowed, removal is not permitted.
              Elevate the learning experience with lasting skills
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/skill-board/create">
                New Skill
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {/* <br />
      </Header> */}
      </Header>

      <MDBox p={2}>
        {skills.length != 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell variant="head">
                  <MDTypography variant="caption">Name</MDTypography>
                </TableCell>
                <TableCell variant="head">
                  <MDTypography variant="caption">Age Group</MDTypography>
                </TableCell>
                {/* <TableCell align="left" variant="head">
                  Date
                </TableCell> */}
                <TableCell align="right" variant="head"></TableCell>
              </TableRow>
              <TableBody>
                {skills.map((skill, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MDTypography variant="caption">{skill.name}</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">
                        <Chip variant="outlined" label={skill.age_group} color="secondary" />
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/skill-board/update/${skill.id}`}
                        className="refresh-icon btn btn primary"
                      >
                        {/* <FontAwesomeIcon icon={faEdit} /> */}
                        <EditIcon style={{ width: "20px" }} />
                      </Link>
                    </TableCell>
                    {/* <TableCell>
                      {moment(user.child?.updated_at).utc().format("YYYY-MM-DD")}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyRecords />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SkillBoard;
