import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const EmptyRecords = () => {
  return (
    <MDBox mb={12} height="100%">
      <MDTypography variant="h3" fontWeight="medium">
        No records to display
      </MDTypography>
    </MDBox>
  );
};

export default EmptyRecords;
