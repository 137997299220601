// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/profile/components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { getUserById } from "service/admin";
import { updateUserById } from "service/admin";
import { getSkillById } from "service/admin";
import { updateSkillById } from "service/admin";
import { ageGroups } from "utils/groups";

function SkillUpdate() {
  const { articledata, setArticleData } = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    age_group: "",
  });

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const res = await getSkillById(id);
      const { age_group, name } = res.data.data;
      const data = {
        age_group,
        name,
      };

      const updateData = {
        ...formData,
        ...data,
      };

      setFormData(updateData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    updateSkill();
  };
  const updateSkill = async () => {
    try {
      const users = await updateSkillById(id, formData);
      if (users.data.status == 200) {
        toast.success("Skill update Successfully");
        navigate("/skill-board");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Update Skill
          </MDTypography>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="name"
                      variant="standard"
                      label="Name"
                      value={formData.name}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-label" fullWidth>
                        Age
                      </InputLabel>
                      <Select
                        label="Age"
                        variant="standard"
                        name="age_group"
                        value={formData.age_group}
                        onChange={handleChange}
                      >
                        {ageGroups.map((group, key) => {
                          return (
                            <MenuItem value={group.id} key={key}>
                              {group.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <MDInput
                      name="email"
                      variant="standard"
                      label="email"
                      value={formData.email}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="phone_number"
                      variant="standard"
                      label="phone number"
                      value={formData.phone_number}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid> */}
                  <Grid item xs={12} alignItems="center" ml={-1}></Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/skill-board"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="gradient" color="success">
                    Save
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SkillUpdate;
