import { Link, useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import avatar1 from "assets/images/avatar/1.png";
import avatar2 from "assets/images/avatar/2.jpg";
import avatar3 from "assets/images/avatar/3.png";
import avatar4 from "assets/images/avatar/4.jpg";
// import logoutIcon from "assets/images/icons/logout.png";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getUserRole } from "service/admin";
import { getAvatars } from "service/admin";
import { getAllDistrict } from "service/admin";
import { createSkill } from "service/admin";
import { ageGroups } from "utils/groups";

function SkillCreate() {
  const { role, setRole } = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const avatars = [avatar1, avatar2, avatar3, avatar4];
  const [avaterlist, setAvatarList] = useState([]);
  const [district, setDistrict] = useState([]);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    age_group: "",
  });

  useEffect(() => {
    userRole();
    getAvatar();
    getDistrictData();
  }, []);

  const userRole = async () => {
    try {
      const res = await getUserRole();
      if (res.data.status == 200) {
        setRole(res.data.data);
      }
    } catch (err) {}
  };

  const getAvatar = async () => {
    try {
      const res = await getAvatars();
      if (res.data.status == 200) {
        setAvatarList(res.data.data);
      }
    } catch (err) {}
  };

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    createSkillData();
  };
  const createSkillData = async () => {
    try {
      const res = await createSkill(formData);
      if (res.data.status == 200) {
        toast.success("Skill create Successfully");
        navigate("/skill-board");
        // setArticleData(res.data.data);
      }
    } catch (err) {
      console.log(err.response.data.message);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleAvatarSelection = (selectedAvatar) => {
    setSelectedAvatar(selectedAvatar);
    setFormData({
      ...formData,
      avatar: selectedAvatar.toString(),
    });
  };

  const saveProfile = () => {
    // Implement logic to save the selected avatar to the user's profile here
  };

  const getDistrictData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllDistrict(query);

      if (contents.data.status === 200) {
        setDistrict(
          contents.data.data.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
        // console.log(contents);
      }
    } catch (err) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Create New Skill
          </MDTypography>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="name"
                      variant="standard"
                      label="Name"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-label" fullWidth>
                        Age
                      </InputLabel>
                      <Select
                        label="Age"
                        variant="standard"
                        name="age_group"
                        value={formData.age_group}
                        onChange={handleChange}
                      >
                        {ageGroups.map((group, key) => {
                          return (
                            <MenuItem value={group.id} key={key}>
                              {group.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <MDInput
                      name="email"
                      variant="standard"
                      label="email"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="phone_number"
                      variant="standard"
                      label="phone number"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  my={2}
                  style={{ padding: "5px" }}
                >
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/skill-board"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="gradient" color="success">
                    Save
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SkillCreate;
