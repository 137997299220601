import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";
import defaultImage from "assets/images//avatar/1.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as xlsx from "xlsx";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { getAllUsers } from "service/admin";
import moment from "moment/moment";
import MDInput from "components/MDInput";
import {
  Autocomplete,
  Card,
  Divider,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getAllBoardDetailData } from "service/admin";
import { getAllGames } from "service/admin";
import "./style.css";
import { getAvatars } from "service/admin";

function AvatarListView() {
  const [userdata, setUserData] = useState([]);
  const [gamedata, setGameData] = useState([]);
  const [phone, setPhone] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [game, setGame] = useState("");
  const [avaterlist, setAvatarList] = useState([]);

  useEffect(() => {
    getAvatar();
  }, []);

  const getAvatar = async () => {
    try {
      const res = await getAvatars();
      if (res.data.status == 200) {
        setAvatarList(res.data.data);
      }
    } catch (err) {}
  };

  const getUsersData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const getGamesData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllGames(query);
      if (contents.data.status === 200) {
        setGameData(contents.data.data);
        // console.log(contents);
      }
    } catch (err) {}
  };

  const getFilter = async () => {
    try {
      // for now
      if (phone) {
        setUserData(userdata.filter((data) => data.child.parent.phone_number == phone));
        return;
      }
      const query = {
        id: game,
        from: startDate
          ? `${new Date(startDate).getFullYear()}-${(
              new Date(startDate).getMonth() +
              1 +
              ""
            ).padStart(2, "0")}-${(new Date(startDate).getDate() + "").padStart(2, "0")}`
          : "",
        to: endDate
          ? `${new Date(endDate).getFullYear()}-${(new Date(endDate).getMonth() + 1 + "")
              .padStart("")
              .padStart(2, "0")}-${(new Date(endDate).getDate() + "").padStart(2, "0")}`
          : "",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reset = async () => {
    setStartDate("");
    setEndDate("");
    setGame("");
    setPhone("");
    try {
      const query = {
        id: "1",
        role: "",
        phonenumber: "",
        from: "",
        to: "",
        game: "",
      };
      const contents = await getAllBoardDetailData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Avatar Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Monitor avatars on user profiles, utilizing filters for performance data oversight.
              Download reports for a detailed analysis of child achievements, providing valuable
              insights into their progress and accomplishments.
            </MDTypography>
            {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/user/create">
                New User
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        {/* <br />
      </Header> */}
        <br />
      </Header>

      <MDBox py={3}>
        {avaterlist.length != 0 ? (
          <Grid container spacing={3}>
            {avaterlist.map((avatar, index) => (
              <Grid key={index} item xs={12} md={6} lg={3}>
                <MDBox mb={2.5}>
                  <Card>
                    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                      <MDBox
                        variant="gradient"
                        bgColor={"success"}
                        color={"white"}
                        coloredShadow={"white"}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        mt={-3}
                      >
                        {/* <Icon fontSize="medium" color="inherit"> */}
                        <img
                          src={
                            avatar.path
                              ? `${process.env.REACT_APP__MAIN_API_BASE_URL}/avatars/${avatar.path}`
                              : defaultImage
                          }
                          width={100}
                          height={100}
                          style={{ marginLeft: "30px" }}
                        />
                        {/* </Icon> */}
                      </MDBox>
                      <MDBox textAlign="right" lineHeight={1.25}>
                        <MDTypography variant="h4">{avatar.name}</MDTypography>
                        <MDTypography variant="button" fontWeight="light" color="text">
                          <Link
                            to={`/avatar/update/${avatar.id}/${avatar.name}/${avatar.path}`}
                            style={{ paddingRight: "5px" }}
                          >
                            <EditIcon
                              className="edit-icon"
                              style={{ backgroundColor: "#1a73e8", width: "30px", height: "30px" }}
                            />
                          </Link>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <Divider />
                    {/* <MDBox pb={2} px={2}>
                      <MDTypography component="p" variant="button" color="text" display="flex">
                        <MDTypography
                          component="span"
                          variant="button"
                          fontWeight="bold"
                          color={"success"}
                        >
                          {"percentage.amoun"}
                        </MDTypography>
                        &nbsp;{"percentage.label"}
                      </MDTypography>
                    </MDBox> */}
                  </Card>
                </MDBox>
              </Grid>
            ))}
          </Grid>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No avatar to display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default AvatarListView;
