// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import { useParams, useNavigate, Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { getContentById } from "service/admin";
import { updateContentById } from "service/admin";
import articleImage from "assets/images/character.png";
import { toast } from "react-toastify";
import { updateAvatarById } from "service/admin";

function UpdateAvatar() {
  const navigate = useNavigate();

  const [imagePath, setImagePath] = useState("");
  const [isImageUploadDisable, setIsImageUploadDisable] = useState(false);
  const { id, name, image } = useParams();

  const [formData, setFormData] = useState({
    name: name,
    image: imagePath,
  });

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name == "link" && (value.includes("youtube.com") || value.includes("youtu.be") || !value)) {
      setIsImageUploadDisable(true);
    } else {
      setIsImageUploadDisable(false);
    }
  };

  useEffect(() => {
    setImagePath(`${process.env.REACT_APP__MAIN_API_BASE_URL}/avatars/${image}`);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    updateAvatar();
  };
  const updateAvatar = async () => {
    try {
      const splitBase64Image = imagePath.split(",")[1];
      const users = await updateAvatarById(id, {
        ...formData,
        image: splitBase64Image ? splitBase64Image : imagePath,
      });
      if (users.status == 200) {
        toast.success("Avatar update Successfully");
        navigate("/avatar");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const setBase64Image = (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImagePath(reader.result);
    }.bind(this);
    setImagePath(event.target.files[0]);
  };

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    if (file?.size > 200000) {
      toast.error(`Avatar size exceeded the limit of 200 KB`);
      return;
    }
    setBase64Image(file);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Avatar Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="h4" color="text">
              Update Avatar
            </MDTypography>
            {/* <MDTypography variant="button" color="text">
              To create new avatar, you&lsquo;ll need to provide a YouTube link, a title, and a
              description. This allows you to seamlessly integrate video content into your platform
              while ensuring that users have access to valuable information. You can also delete
              outdated materials and update existing information with ease.
            </MDTypography> */}
          </MDBox>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container>
                  <Grid container item xs={12} md={8} spacing={3}>
                    <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                      <Grid item>
                        <img
                          width="100%"
                          src={imagePath ? `${imagePath}` : articleImage}
                          style={{
                            width: 100,
                            height: 100,
                            margin: "auto",
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            paddingBottom: "10px",
                            borderRadius: "20%",
                          }}
                        />
                      </Grid>
                      <label>
                        <MDButton
                          variant="gradient"
                          component="span"
                          color={isImageUploadDisable ? "" : "success"}
                          disabled={isImageUploadDisable}
                        >
                          Select Image
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleUploadClick}
                          />
                        </MDButton>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <MDInput
                        style={{ marginTop: "90px" }}
                        name="name"
                        variant="standard"
                        label="Name"
                        value={formData.name}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} alignItems="center" ml={-1}></Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/avatar"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="gradient" color="success">
                    Update
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateAvatar;
