/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "app/admin/dashboard";
import Notifications from "layouts/notifications";
import Article from "app/admin/pages/article/Article";
import CreateArticle from "app/admin/pages/article/Create";
import Update from "app/admin/pages/article/Update";
import SingleArticle from "app/admin/pages/article/SingleArticle";
import Users from "app/admin/pages/users";

// @mui icons
import Icon from "@mui/material/Icon";
import UserUpdate from "./pages/users/Update";
import AdminUsers from "./pages/admin";
import UserCreate from "./pages/users/Create";
import AdminCreate from "./pages/admin/Create";
import AdminUserUpdate from "./pages/admin/Update";
import LeaderBoard from "./pages/leaderboard";
import SkillBasedLeaderBoard from "./pages/skill-leaderboard";
import SkillBoard from "./pages/skill-board";
import SkillCreate from "./pages/skill-board/Create";
import SkillUpdate from "./pages/skill-board/Update";
import AvatarListView from "./pages/avatar";
import LocalDashboard from "./pages/dashboard";
import UpdateAvatar from "./pages/avatar/Update";
import GameList from "./pages/games/GameList";

const routes = [
  {
    type: "collapse",
    name: "MixPanel Dashboard",
    key: "mixpanel-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/mixpanel-dashboard",
    component: <Dashboard />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <LocalDashboard />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Articles",
    key: "articles",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/articles",
    component: <Article />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin-users",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/admin-users",
    component: <AdminUsers />,
    isNavbar: true,
    roles: ["superadmin"],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/users",
    component: <Users />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Admin Users",
    key: "admin-user",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/admin-user/update/:id",
    component: <AdminUserUpdate />,
    isNavbar: false,
    roles: ["superadmin"],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/user/update/:id",
    component: <UserUpdate />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/user/create",
    component: <UserCreate />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin-user",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/admin-user/create",
    component: <AdminCreate />,
    isNavbar: false,
    roles: ["superadmin"],
  },
  {
    type: "collapse",
    name: "Article",
    key: "article",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/article/create",
    component: <CreateArticle />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Article",
    key: "article",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/article/update/:id",
    component: <Update />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Article",
    key: "article",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/article/:id",
    component: <SingleArticle />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "admin/notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Game Leaderboard",
    key: "leaderboard",
    icon: <Icon fontSize="small">leaderboard_icon</Icon>,
    route: "/leaderboard",
    component: <LeaderBoard />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Skill Leaderboard",
    key: "skill-leaderboard",
    icon: <Icon fontSize="small">leaderboard_icon</Icon>,
    route: "/skill-leaderboard",
    component: <SkillBasedLeaderBoard />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Skill Board",
    key: "skill-board",
    icon: <Icon fontSize="small">leaderboard_icon</Icon>,
    route: "/skill-board",
    component: <SkillBoard />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Avatar Management",
    key: "avatar",
    icon: <Icon fontSize="small">leaderboard_icon</Icon>,
    route: "/avatar",
    component: <AvatarListView />,
    isNavbar: true,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Avatar",
    key: "avatar",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/avatar/update/:id/:name/:image",
    component: <UpdateAvatar />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Skill Board",
    key: "skill-board",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/skill-board/create",
    component: <SkillCreate />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Skill Board",
    key: "skill-board",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/skill-board/update/:id",
    component: <SkillUpdate />,
    isNavbar: false,
    roles: ["admin", "superadmin", "monitor"],
  },
  {
    type: "collapse",
    name: "Game Board",
    key: "game-board",
    icon: <Icon fontSize="small">sports_esports</Icon>,
    route: "/game-board",
    component: <GameList />,
    isNavbar: true,
    roles: ["superadmin"],
  },
];

export default routes;
