import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footer";

// About Us page sections
import Information from "./components/Information";
import Featuring from "./components/Featuring";
import Newsletter from "./components/Newsletter";
import ReactPlayer from "react-player/youtube";
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import backgroundImage from "assets/images/3639829.jpg";

function LandingPage() {
  return (
    <>
      <BasicLayout image={backgroundImage}>
        <Container maxWidth="lg">
          <MDBox
            minHeight="75vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.6),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={8}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <MDTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Start Smart Admin User Section
                </MDTypography>
                <MDTypography variant="title" color="white" opacity={0.8} mt={1} mb={3}>
                  Navigate, Analyze, Optimize. Effortlessly manage user data and enhance system
                  performance. Your path to efficient administration starts here!
                </MDTypography>
                <MDButton
                  component={Link}
                  to="/signin"
                  color="white"
                  shadow="large"
                  style={{
                    textTransform: "none",
                    padding: "20px 20px",
                    fontSize: "22px",
                    margin: "5px",
                  }}
                >
                  Get Started
                </MDButton>
              </Grid>
            </Container>
          </MDBox>
        </Container>
      </BasicLayout>
    </>
  );
}

export default LandingPage;
