import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import uploadImg from "assets/images/upload-1.png";

import { useState } from "react";
import { postContents } from "service/admin";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

function Article() {
  const navigate = useNavigate();

  const [image, setImage] = useState("");
  const [isImageUploadDisable, setIsImageUploadDisable] = useState(true);

  const [formData, setFormData] = useState({
    title: "",
    link: "",
    type: "mrk", // Default type
    description: "",
    image: "",
  });

  // Step 2: Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name == "link" && (value.includes("youtube.com") || value.includes("youtu.be") || !value)) {
      setIsImageUploadDisable(true);
    }
    if (name == "link" && !(value.includes("youtube.com") || value.includes("youtu.be")) && value) {
      setIsImageUploadDisable(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    postArticleData();
  };

  const postArticleData = async () => {
    try {
      const splitBase64Image = image.split(",")[1];
      const users = await postContents({
        ...formData,
        image: splitBase64Image ? splitBase64Image : formData.image,
      });
      if (users.status == 200) {
        toast.success("Article create Successfully");
        // setArticleData(users.data.data);
        navigate("/articles");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    if (file?.size > 200000) {
      toast.error(`Image size exceeded the limit of 200 KB`);
      return;
    }
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImage(reader.result);
    }.bind(this);
    setImage(event.target.files[0]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Content Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="h4" color="text">
              Create New Article
            </MDTypography>
            <MDTypography variant="button" color="text">
              Adding fresh content is a breeze. For instance, if you wish to incorporate a YouTube
              video, simply insert the direct link, and our platform will seamlessly integrate it.
              In the case of articles, upload an image along with a description to enrich your
              content. Our user-friendly interface ensures a hassle-free experience as you bring
              your ideas to life.
            </MDTypography>
          </MDBox>

          <MDBox mb={1}>
            <MDBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MDBox p={3}>
                <Grid container>
                  <Grid container item xs={12} md={8} spacing={3}>
                    <Grid item xs={12} md={8}>
                      <MDInput
                        name="title"
                        variant="standard"
                        label="Title"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <MDInput
                        name="link"
                        variant="standard"
                        label="Youtube Link"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          style={{ fontSize: "14px" }}
                        >
                          Type
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="type"
                          label="Type"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="mrk"
                            control={<Radio />}
                            label="Marketing"
                            checked={formData.type === "mrk"}
                          />
                          <FormControlLabel value="edu" control={<Radio />} label="Educational" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <MDInput
                        name="description"
                        variant="standard"
                        label="Description"
                        multiline
                        fullWidth
                        rows={6}
                        onChange={handleChange}
                        inputProps={{ maxLength: 2500 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                    <Grid item>
                      <img
                        width="100%"
                        src={image ? image : uploadImg}
                        style={{
                          width: 100,
                          height: 100,
                          margin: "auto",
                          display: "block",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          paddingBottom: "10px",
                          borderRadius: "20%",
                        }}
                      />
                    </Grid>
                    <label>
                      <MDButton
                        variant="gradient"
                        component="span"
                        color={isImageUploadDisable ? "" : "success"}
                        disabled={isImageUploadDisable}
                      >
                        Select Image
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleUploadClick}
                        />
                      </MDButton>
                    </label>
                  </Grid>
                  <Grid item xs={12} alignItems="center" ml={-1}></Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MDButton type="submit" variant="gradient" color="success">
                    Publish Article
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        <Grid container spacing={6}></Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Article;
