import { Grid } from "@mui/material";
import "./index.css";
import MDBox from "components/MDBox";

const AvatarSelection = ({ avatars, selectedAvatar, onSelectAvatar, saveProfile }) => {
  console.log(avatars);
  return (
    <div className="avatar-list">
      {avatars.map((avatar, index) => (
        <div
          key={index}
          className={`avatar-container ${selectedAvatar === avatar.id ? "selected" : ""}`}
          onClick={() => onSelectAvatar(avatar.id)}
        >
          <img
            src={`${process.env.REACT_APP__MAIN_API_BASE_URL}/avatars/${avatar.path}`}
            alt={`Avatar ${index}`}
            className="avatar"
          />
        </div>
      ))}
      {/* <button onClick={saveProfile}>Save Profile</button> */}
    </div>
  );
};

export default AvatarSelection;
