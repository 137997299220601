import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as xlsx from "xlsx";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import moment from "moment/moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllSkillBoardData } from "service/admin";
import { getAllSkills } from "service/admin";
import EmptyRecords from "components/EmptyRecords";

const ageGroups = [
  {
    id: "1-2",
    name: "1-2",
  },
  {
    id: "2-3",
    name: "2-3",
  },
  {
    id: "3-4",
    name: "3-4",
  },
  {
    id: "4-5",
    name: "4-5",
  },
];

function SkillBasedLeaderBoard() {
  const [userdata, setUserData] = useState([]);
  const [gamedata, setGameData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectSkill, setSelectSkill] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [game, setGame] = useState("");
  const [age, setAge] = useState("");

  useEffect(() => {
    getUsersData();
    getSkillData();
    Promise.all([getUsersData(), getSkillData()]).then((values) => {
      console.log(values);
    });
  }, []);

  const getUsersData = async () => {
    try {
      const query = {
        id: "2",
      };
      const contents = await getAllSkillBoardData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const getSkillData = async () => {
    try {
      const query = {
        id: "1",
      };
      const contents = await getAllSkills(query);
      if (contents.data.status === 200) {
        setSkills(contents.data.data);
        // console.log(contents);
      }
    } catch (err) {}
  };

  const getFilter = async () => {
    try {
      const query = {
        id: selectSkill,
        from: startDate
          ? `${new Date(startDate).getFullYear()}-${(
              new Date(startDate).getMonth() +
              1 +
              ""
            ).padStart(2, "0")}-${(new Date(startDate).getDate() + "").padStart(2, "0")}`
          : "",
        to: endDate
          ? `${new Date(endDate).getFullYear()}-${(new Date(endDate).getMonth() + 1 + "")
              .padStart("")
              .padStart(2, "0")}-${(new Date(endDate).getDate() + "").padStart(2, "0")}`
          : "",
        age,
      };
      const contents = await getAllSkillBoardData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reset = async () => {
    setStartDate("");
    setEndDate("");
    setSelectSkill("");
    try {
      const query = {
        id: "1",
        from: "",
        to: "",
      };
      const contents = await getAllSkillBoardData(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reportDownload = () => {
    const dataToExport = [];
    userdata.forEach(({ top_score, child }) => {
      dataToExport.push({
        Name: child.first_name + " " + child.last_name,
        "Top Score": top_score,
        "Parent User": child.parent.first_name + " " + child.parent.last_name,
        Date: moment(child?.updated_at).utc().format("YYYY-MM-DD"),
      });
    });
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Users");
    xlsx.writeFile(workbook, "skill_data.xlsx");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Skill Leaderboard
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Similar to the game leaderboard, you can refine the data by specifying the time period
              or a particular skill of interest. The ability to download the data as a report
              empowers you to analyze and share information seamlessly, offering a robust solution
              for tracking and showcasing skill-related achievements.
            </MDTypography>
            {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/user/create">
                New User
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        {/* <br />
      </Header> */}

        {/* <Header> */}
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDBox mb={1}>
            <MDBox mt={1} mb={1}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                    <DatePicker
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                    <Grid item xs={12} md={8}>
                      <Grid md={4}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label" fullWidth>
                            Age
                          </InputLabel>
                          <Select
                            style={{ width: "120px", height: "42px" }}
                            label="Age"
                            variant="outlined"
                            name="age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                          >
                            {ageGroups.map((group, key) => {
                              return (
                                <MenuItem value={group.id} key={key}>
                                  {group.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label" fullWidth>
                          Skill
                        </InputLabel>
                        <Select
                          style={{ width: "120px", height: "42px" }}
                          label="skill"
                          variant="outlined"
                          name="role_id"
                          value={selectSkill}
                          onChange={(e) => setSelectSkill(e.target.value)}
                        >
                          {skills.map((game, key) => {
                            return (
                              <MenuItem value={game.id} key={key}>
                                {game.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <MDButton variant="gradient" color="info" onClick={getFilter}>
                      Filter
                    </MDButton>
                    <MDButton variant="gradient" color="dark" onClick={reset}>
                      Rest
                    </MDButton>
                    <MDButton variant="gradient" color="success" onClick={reportDownload}>
                      Report Download
                    </MDButton>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>

      <MDBox p={2}>
        {userdata.length != 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell variant="head">
                  <MDTypography variant="caption">First Name</MDTypography>
                </TableCell>
                <TableCell variant="head">
                  <MDTypography variant="caption">Last Name</MDTypography>
                </TableCell>
                <TableCell variant="head">
                  <MDTypography variant="caption">Parent Name</MDTypography>
                </TableCell>
                <TableCell variant="head">
                  <MDTypography variant="caption">Parent Phone</MDTypography>
                </TableCell>
                <TableCell align="left" variant="head">
                  Top Score
                </TableCell>
                {/* <TableCell align="left" variant="head">
                  Date
                </TableCell> */}
                <TableCell align="right" variant="head"></TableCell>
              </TableRow>
              <TableBody>
                {userdata.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MDTypography variant="caption">{user.child_firstname}</MDTypography>{" "}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">{user.child_lastname}</MDTypography>{" "}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">
                        {user.user_firstname} {user.user_lastname}
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">{user.user_phonenumber}</MDTypography>{" "}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption"> {user.score}</MDTypography>{" "}
                    </TableCell>
                    {/* <TableCell>
                      {moment(user.child?.updated_at).utc().format("YYYY-MM-DD")}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyRecords />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SkillBasedLeaderBoard;
