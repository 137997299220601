/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useEffect, useState } from "react";
import { getDashboard } from "service/admin";
import { Line } from "react-chartjs-2";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";

function LocalDashboard() {
  const [dashboard, setDashboard] = useState([]);
  const [taskData, setTaskData] = useState({
    labels: [],
    datasets: { label: "Desktop apps", data: [] },
  });
  const [taskData1, setTaskData1] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        legend: {
          display: false,
        },
        label: "First dataset",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "green",
      },
    ],
  });
  const [dailyTask, setDialyTask] = useState({
    labels: ["2024-01-01", "2024-01-01", "2024-01-01"],
    datasets: [
      {
        legend: {
          display: false,
        },
        label: "First dataset",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "green",
      },
    ],
  });
  const [userDistrictData, setUserDistrictData] = useState({
    labels: ["Galle", "Colombo", "Gampaha", "Matara"],
    datasets: [
      {
        legend: {
          display: false,
        },
        label: "First dataset",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "green",
      },
    ],
  });
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  function convertToMonthNames(monthNumbers) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const result = monthNumbers.map((num) => monthNames[num - 1]);
    return result;
  }

  const extractDayFromDates = (dateCountArray) => {
    return dateCountArray.map(({ date, count }) => {
      const day = new Date(date).toLocaleDateString("en-US", { day: "numeric" });
      return { day, count };
    });
  };
  let task12;
  const getDashboardData = async () => {
    try {
      const contents = await getDashboard();
      if (contents.data.status === 200) {
        setDashboard(contents.data.data);
        setTaskData({
          labels: convertToMonthNames(
            contents.data.data?.parents_count_by_date?.map((data) => data.month)
          ),
          datasets: [
            {
              label: "Desktop apps",
              data: contents.data.data.parents_count_by_date?.map((data) => data.count),
            },
          ],
        });
        setTaskData1({
          labels: convertToMonthNames(
            contents.data.data?.parents_count_by_date?.map((data) => data.month)
          ),
          datasets: [
            {
              label: "",
              data: contents.data.data.parents_count_by_date?.map((data) => data.count),
              fill: false,
              backgroundColor: "green",
              borderColor: "green",
            },
          ],
        });
        const districtLabels = Object.keys(contents.data.data?.users_by_district);
        setUserDistrictData({
          labels: districtLabels,
          datasets: [
            {
              label: "",
              data: districtLabels.map(
                (label) => contents.data.data?.users_by_district[label].length
              ),
              fill: false,
              backgroundColor: "green",
              borderColor: "green",
            },
          ],
        });
        const outputArray = extractDayFromDates(contents.data.data?.parents_count_daily);
        setDialyTask({
          labels: contents.data.data.parents_count_daily?.map((data) => data.date),
          datasets: [
            {
              label: "",
              data: contents.data.data.parents_count_daily?.map((data) => data.count),
              fill: false,
              backgroundColor: "green",
              borderColor: "green",
            },
          ],
        });
      }
    } catch (err) {
      // Handle error appropriately
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="school"
                title="Education Content"
                count={dashboard?.educational_content_count}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of contents published",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="campaign"
                title="Marketing Contents"
                count={dashboard?.marketing_content_count}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of contents published",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={"sports_esports"}
                title="Game Count"
                count={dashboard?.game_count}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of games added to the platform",
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="boy"
                title="Child User Count"
                count={dashboard?.child_count}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of child accounts created",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="escalator_warning"
                title="Parent Count"
                count={dashboard?.parent_count}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of parent accounts created",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="person_add"
                title="Active Users"
                count={
                  dashboard?.parent_count
                    ? dashboard?.parent_count - dashboard?.parents_with_no_children
                    : 0
                }
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of active parent accounts",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Inactive Users"
                count={dashboard?.parents_with_no_children}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of inactive parent accounts",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={taskData}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <Card sx={{ height: "100%" }}>
                  <MDBox padding="1rem">
                    <MDBox
                      variant="gradient"
                      bgColor={"dark"}
                      borderRadius="lg"
                      coloredShadow={"dark"}
                      py={2}
                      pr={0.5}
                      mt={-5}
                      height="12.5rem"
                    >
                      <Line data={taskData1} redraw options={options} />
                    </MDBox>
                    <MDBox pt={3} pb={1} px={1}>
                      <MDTypography variant="h6" textTransform="capitalize">
                        User Growth - by monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <Card sx={{ height: "100%" }}>
                  <MDBox padding="1rem">
                    <MDBox
                      variant="gradient"
                      bgColor={"dark"}
                      borderRadius="lg"
                      coloredShadow={"dark"}
                      py={2}
                      pr={0.5}
                      mt={-5}
                      height="12.5rem"
                    >
                      <Line data={dailyTask} redraw options={options} />
                    </MDBox>
                    <MDBox pt={3} pb={1} px={1}>
                      <MDTypography variant="h6" textTransform="capitalize">
                        User Growth - this week
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <Card sx={{ height: "100%" }}>
                  <MDBox padding="1rem">
                    <MDBox
                      variant="gradient"
                      bgColor={"dark"}
                      borderRadius="lg"
                      coloredShadow={"dark"}
                      py={2}
                      pr={0.5}
                      mt={-5}
                      height="12.5rem"
                    >
                      <Line data={userDistrictData} redraw options={options} />
                    </MDBox>
                    <MDBox pt={3} pb={1} px={1}>
                      <MDTypography variant="h6" textTransform="capitalize">
                        User Growth - by District
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default LocalDashboard;
