import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as xlsx from "xlsx";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
var youtubeThumbnail = require("youtube-thumbnail");
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import { getAllUsers } from "service/admin";
import moment from "moment/moment";
import MDInput from "components/MDInput";
import { getRole } from "core/services/auth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { deleteUserById } from "service/admin";
import "./style.css";

function Article() {
  const [userdata, setUserData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    console.log(userdata);
    getUsersData();
  }, []);

  const getUsersData = async () => {
    try {
      const query = {};
      const contents = await getAllUsers(query);

      console.log(contents);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
        console.log(contents);
      }
    } catch (err) {}
  };

  const getFilter = async () => {
    try {
      const query = {
        phonenumber: phone,
        from: startDate
          ? `${new Date(startDate).getFullYear()}-${(
              new Date(startDate).getMonth() +
              1 +
              ""
            ).padStart(2, "0")}-${(new Date(startDate).getDate() + "").padStart(2, "0")}`
          : "",
        to: endDate
          ? `${new Date(endDate).getFullYear()}-${(new Date(endDate).getMonth() + 1 + "")
              .padStart("")
              .padStart(2, "0")}-${(new Date(endDate).getDate() + "").padStart(2, "0")}`
          : "",
      };
      const contents = await getAllUsers(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reset = async () => {
    setStartDate("");
    setEndDate("");
    setPhone("");
    try {
      const query = {
        role: "",
        phonenumber: "",
        from: "",
        to: "",
      };
      const contents = await getAllUsers(query);
      if (contents.data.status === 200) {
        setUserData(contents.data.data);
      }
    } catch (err) {}
  };

  const reportDownload = () => {
    const dataToExport = [];
    const userDatawithChild = [];
    userdata.map((data) => {
      if (data.child_profiles.length) {
        data.child_profiles.map((profile) => {
          const child = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            address: data.address,
            // date: moment(data.created_at).utc().format("YYYY-MM-DD"),
            child: {
              first_name: profile.first_name,
              last_name: profile.first_name,
              parent_first_name: profile.parent.first_name,
              parent_last_name: profile.parent.last_name,
              parent_phone_number: profile.parent.phone_number,
              parent_address: profile.parent.address,
              parent_email: profile.parent.email,
            },
          };
          userDatawithChild.push(child);
        });
      } else {
        const withoutChild = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
        };
        userDatawithChild.push(withoutChild);
      }
    });
    userDatawithChild.forEach(
      ({ first_name, last_name, email, address, phone_number, child, date }) => {
        dataToExport.push({
          Name: first_name + " " + last_name,
          Email: email,
          Phone: phone_number,
          Address: address,
          "Children Name": child?.first_name ? child?.first_name + " " + child?.last_name : "",
          "Parent Name": child?.parent_first_name
            ? child?.parent_first_name + " " + child?.parent_first_name
            : "",
          "Parent Phone": child?.parent_phone_number ? child?.parent_phone_number : "",
          "Parent Address": child?.parent_address ? child?.parent_address : "",
          "Parent Email": child?.parent_email ? child?.parent_email : "",
          // Date: date,
        });
      }
    );
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Users");
    xlsx.writeFile(workbook, "users.xlsx");
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setUserId(id);
  };

  const handleResetClickOpen = (id) => {
    setResetOpen(true);
    setUserId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setResetOpen(false);
  };

  const deleteAccount = async () => {
    try {
      await deleteUserById(userId);
      setOpen(false);
      getUsersData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            User Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Effortlessly generate new user profiles, update existing details, and swiftly filter
              user data as needed. The system also offers a convenient report download option,
              streamlining user management for optimal efficiency and ease of use.
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/user/create">
                New User
              </MDButton>
            </MDBox>
          </MDBox>
          {/* <Header> */}
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDBox mb={1}>
              <MDBox mt={1} mb={1}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        format="YYYY-MM-DD"
                      />
                      <DatePicker
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        format="YYYY-MM-DD"
                      />
                      <Grid item xs={12} md={8}>
                        <MDInput
                          name="phone_number"
                          variant="outlined"
                          label="+94XXX or 071XXX"
                          fullWidth
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Grid>
                      <MDButton variant="gradient" color="info" onClick={getFilter}>
                        Filter
                      </MDButton>
                      <MDButton variant="gradient" color="dark" onClick={reset}>
                        Rest
                      </MDButton>
                      <MDButton variant="gradient" color="success" onClick={reportDownload}>
                        Report Download
                      </MDButton>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
          <br />
          {/* </Header> */}
        </MDBox>
        <br />
      </Header>

      <MDBox p={2}>
        {userdata.length != 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell variant="head">
                  <MDTypography variant="caption">Name</MDTypography>
                </TableCell>
                <TableCell align="left" variant="head">
                  <MDTypography variant="caption">Phone</MDTypography>
                </TableCell>
                <TableCell align="left" variant="head">
                  <MDTypography variant="caption">Date</MDTypography>
                </TableCell>
                <TableCell align="right" variant="head"></TableCell>
              </TableRow>
              <TableBody>
                {userdata.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MDTypography variant="caption">{user.first_name}</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">{user.phone_number}</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="caption">
                        {moment(user.created_at).utc().format("YYYY-MM-DD")}
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <Link to={`/user/update/${user.id}`} className="refresh-icon btn btn primary">
                        {/* <FontAwesomeIcon icon={faEdit} /> */}
                        <EditIcon />
                      </Link>
                      <Link to="" onClick={() => handleClickOpen(user.id)} className="delete-icon">
                        <DeleteIcon />
                      </Link>
                      <Link
                        to=""
                        onClick={() => handleResetClickOpen(user.id)}
                        className="refresh-icon"
                      >
                        <LockOpenIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MDBox mb={12} height="100%">
            <MDTypography variant="h3" fontWeight="medium">
              No users to Display
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be undone once you remove
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton color={"dark"} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton color="error" onClick={deleteAccount}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resetOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to update?"}</DialogTitle>
        <DialogContent>
          <MDInput
            name="text"
            variant="standard"
            label="password"
            fullWidth
            onChange={(e) => setResetPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MDButton color={"dark"} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton color="success" onClick={deleteAccount}>
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Article;
