import userClient from "core/services/instance";

export const getAllUsers = (params) => {
  return userClient.get(`/admin/users`, { params });
};

export const getUserById = (id) => {
  return userClient.get(`/admin/users/${id}`);
};
export const updateUserById = (id, data) => {
  return userClient.put(`/admin/users/${id}`, data);
};

export const updateSkillById = (id, data) => {
  return userClient.put(`/admin/skills/${id}`, data);
};

export const getUserRole = () => {
  return userClient.get(`/admin/roles`);
};

export const getContents = () => {
  return userClient.get(`/admin/contents`);
};
export const postContents = (data) => {
  return userClient.post(`/admin/contents`, data);
};
export const createUser = (data) => {
  return userClient.post(`/admin/users`, data);
};

export const createSkill = (data) => {
  return userClient.post(`/admin/skills`, data);
};

export const getContentById = (id) => {
  return userClient.get(`/admin/contents/${id}`);
};

export const createContentById = (id, data) => {
  return userClient.post(`/parent/content/${id}`, data);
};

export const updateContentById = (id, data) => {
  return userClient.put(`/admin/contents/${id}`, data);
};

export const getUserProfiledata = () => {
  return userClient.get(`/userprofile`);
};

export const getChildProfiledata = () => {
  return userClient.get(`/parent/childprofiles`);
};

export const deleteArticleById = (id) => {
  return userClient.delete(`/admin/contents/${id}`);
};

export const getAllBoardDetailData = (params) => {
  return userClient.get(`/admin/leaderboard`, { params });
};

export const getAllSkillBoardData = (params) => {
  return userClient.get(`/admin/skillleaderboard`, { params });
};

export const getAllGames = (params) => {
  if (params) {
    return userClient.get(`/admin/games`, { params });
  }
  return userClient.get(`/admin/games`);
};

export const getAvatars = (params) => {
  return userClient.get(`/avatars`);
};

export const updateAvatarById = (id, data) => {
  return userClient.put(`/admin/avatars/${id}`, data);
};

export const getAllDistrict = () => {
  return userClient.get(`/districts `);
};

export const deleteUserById = (id) => {
  return userClient.delete(`/admin/users/${id}`);
};

export const getAllSkills = (params) => {
  return userClient.get(`/admin/skills`);
};

export const getSkillById = (id) => {
  return userClient.get(`/admin/skills/${id}`);
};

export const getDashboard = (params) => {
  return userClient.get(`/admin/dashboard`);
};
