import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState } from "react";
import CustomizedSnackbars from "components/AlertNotification";
import { getAllGames } from "service/admin";
import EmptyRecords from "components/EmptyRecords";

function GameList() {
  const [games, setGames] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getGameData();
  }, [isDelete]);

  const getGameData = async () => {
    try {
      const params = {
        agegroups: "all",
      };
      const contents = await getAllGames(params);
      if (contents.data.status === 200) {
        const games = Object.keys(contents.data.data).map((key) => {
          const groupGameObject = {
            group: key,
            data: contents.data.data[key],
          };
          return groupGameObject;
        });
        setGames(games);
      }
      setIsDelete(false);
    } catch (err) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        {isDelete && <CustomizedSnackbars open={open} setOpen={setOpen} />}
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h3" fontWeight="medium">
            Game Management
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Here, you have the power to shape your content the way you want it. Whether
              you&apos;re creating new material, removing unnecessary information, or updating
              existing data, the process is intuitive and straightforward.
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" component={Link} to="/" disabled={true}>
                New Game Coming Soon
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <br />
      </Header>
      <MDBox p={2}>
        {games?.length != 0 ? (
          <Grid>
            {games?.map((group, index) => {
              return (
                <Grid container spacing={6} key={{ index }}>
                  <Grid item xs={12} md={12} mt={2}>
                    <MDTypography variant="h4" fontWeight="medium">
                      Age Group {group.group}
                    </MDTypography>
                    <Divider flexItem />
                  </Grid>
                  {group?.data?.games?.map((game, index) => (
                    <Grid item xs={12} md={6} xl={3} key={index}>
                      <BlogCard
                        id={game.id}
                        image={game?.image}
                        title={game?.name}
                        action={{
                          label: "Update Game",
                          color: "dark",
                          route: "/game/" + game?.id,
                          type: "internal",
                          link: game?.link,
                          listType: "game",
                        }}
                        description={`Age Group - ${game?.age_group}`}
                        isYoutube={false}
                        setIsDelete={setIsDelete}
                        setAlertOpen={setOpen}
                      />
                    </Grid>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <EmptyRecords />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default GameList;
