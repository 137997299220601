export const ageGroups = [
  {
    id: "1-2",
    name: "1-2",
  },
  {
    id: "2-3",
    name: "2-3",
  },
  {
    id: "3-4",
    name: "3-4",
  },
  {
    id: "4-5",
    name: "4-5",
  },
];
